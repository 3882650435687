import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		redirect: '/inquiry'
	},
	{
		path: '/inquiry',
		name: 'inquiry',
		component: () => import('@/views/scoreInquiry/scoreInquiry.vue'),
		meta: { title: '成绩查询' }
	},
	{
		path: '/result',
		name: 'result',
		component: () => import('@/views/scoreInquiry/searchResul.vue'),
		meta: { title: '查询结果' }
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})
router.beforeEach((to, from, next) => {
	document.title = to.meta.title
	next()

})
export default router
